import React, { useState, createContext } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import ArTile from '../components/ar-tile'
import ArGrid from '../components/ar-grid'
import GlobalSpotifyPlayer from '../components/global-spotify-player'
import { ArPageProvider } from '../contexts'
import ArTitle from '../components/ar-title'
import ArText from '../components/ar-text'
import { isWarpC } from '../lib/helpers'

export const query = graphql`
query ArPageTemplateQuery($id: String!) {
  page: sanityArPage(id: { eq: $id }) {
    id
    title
    _rawContent(resolveReferences: { maxDepth: 3 })
  }
  site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    title
    openGraph {
      title
      description
      image {
        ...SanityImage
      }
    }
  }
}
`

// export const SpotifyContext = createContext()

const ArPage = props => {
  const { data, errors } = props
  console.log(data)

  if (errors) {
    return <GraphQLErrorList errors={errors} />
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const { page } = data

  const content = (page._rawContent || []).map((c, i) => {
    let el = null
    switch (c._type) {
      case 'arText':
        el = <ArText key={c._key} {...c}/>
        break
      case 'arTitle':
        el = <ArTitle key={c._key} {...c}/>
        break
      case 'arTile':
        el = <div key={c._key}><ArTile {...c} isFullscreen={true}/></div>
        break
      case 'arGrid':
        el = <ArGrid key={c._key} {...c} />
        break
      default:
        el = <div key={c._key}>UNDEFINED {c._type}</div>
    }
    return el
  })

  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const { description, image } = site.openGraph
  const title = pageTitle ? pageTitle : site.title
  return (
    <>
      <SEO title={title} description={description} image={image} noindex={true}/>
      <ArPageProvider>
        {content}
        <GlobalSpotifyPlayer />
      </ArPageProvider>
    </>
  )
}

export default ArPage
