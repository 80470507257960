import React from 'react'
import { useState } from 'react'
import { createContext } from 'react'

export const ArPageContext = createContext()

export function ArPageProvider({ children }) {
  const [player, setPlayer] = useState()
  return (
    <ArPageContext.Provider value={{ player, setPlayer }}>{children}</ArPageContext.Provider>
  )
}
