import React from 'react'
import PortableText from './portableText'
import defaultSerializers from './serializers'

function ArText({ body }) {
  return (
    <div className="ar-text mob-p-1 des-p-1 mob-pb-3 des-pb-3">
      <PortableText
        blocks={body}
        serializers={{
          marks: defaultSerializers.marks,
          types: {
            ...defaultSerializers.types,
            block: props => {
              switch (props.node.style) {
                case 'small':
                  return <h3 className='text-xs text-caps'>{props.children}</h3>
                default:
                  return <p className='text-m-plus'>{props.children}</p>
              }
            },
          },
        }}
      />
    </div>
  )
}

export default ArText
