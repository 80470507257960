import React from 'react'
import ImageLoader from './image-loader'
import { ArPageContext } from '../contexts'
import { useContext } from 'react'
import { defaultSerializers } from '@sanity/block-content-to-react'
import PortableText from './portableText'

function ArTile({ title, subTitle, spotifyEmbed, discoEmbed, image, description, isFullscreen, spotify }) {
  const { setPlayer } = useContext(ArPageContext)
  function handleOnClick(e) {
    let value
    if (spotify) {
      value = {
        _type: spotify.spotifyOrDisco === "spotify" ? 'spotifyEmbed' : 'discoEmbed',
        url: spotify.id
      }
    }
    else {
      value = spotifyEmbed ? spotifyEmbed : discoEmbed
    }
    setPlayer(value)
  }

  return (
    <ul className={`ar-tile mob-p-1 des-p-1 mob-pb-3 des-pb-3 ${isFullscreen ? 'is-fullscreen' : ''}`}>
      <li>
        <div className="ar-tile__title">{title}</div>
        {subTitle?.length > 0 && <div className='ar-tile__sub-title'>{subTitle}</div>}
      </li>
      <li className='ar-tile__image-outer-wrapper mob-mt-1 des-mt-1'>
        <button
          onClick={handleOnClick}
          className="ar-tile__image-wrapper rounded-button__hover-area image-filter"
        >
          <div className="image-filter__image-wrapper">
            <ImageLoader imageData={image} widths={[250, 350, 720]} />
          </div>
          { (spotify?.id || spotifyEmbed?.url || discoEmbed?.url) && <div className="rounded-button ar-tile__button --link-watch">Listen</div>}
        </button>
        <div className="rounded-button ar-tile__mobile-button --link-watch mob-m-0_5">Listen</div>
      </li>
      {description?.length && <PortableText blocks={description}/>}
    </ul>
  )
}

export default ArTile
