import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { ArPageContext } from '../contexts'
import CloseButton from '../components/close-button'
import { setDiscoEmbedUrl, setSpotifyEmbedUrl } from '../lib/helpers'

const transitionDelay = 108

function GlobalSpotifyPlayer() {
  const { player, setPlayer } = useContext(ArPageContext)
  const [height, setHeight] = useState(235)
  const [currentPlayer, setCurrentPlayer] = useState()
  const [slideIn, setSlideIn] = useState(false)

  useEffect(() => {
    if (currentPlayer) {
      setSlideIn(false)
      setTimeout(() => {
        setCurrentPlayer(player)
      }, transitionDelay)
    } else {
      setCurrentPlayer(player)
    }
  }, [player])

  function handleOnCloseClick() {
    setSlideIn(false)
    setTimeout(() => {
      setPlayer(null)
      setCurrentPlayer(null)
    }, transitionDelay)
  }

  useEffect(() => {
    if (currentPlayer?._type === "discoEmbed") {
      setHeight(235)
    }
    else if (currentPlayer?._type === "spotifyEmbed") {
      setHeight(80)
    }
  },
  [currentPlayer?._type])


  return (
    <div className={`global-spotify-player no-border-top ${currentPlayer?._type ?? ""}`}>
      {currentPlayer && player.url ? (
        <div
          style={{
            transition: `transform ${transitionDelay}ms ease-in`,
            ...(slideIn ? { transform: 'translateY(0)' } : {}),
          }}
        >
          <iframe 
            allowfullscreen
            frameborder="0"
            class="disco-embed"
            src={player?._type === "discoEmbed"
            ? setDiscoEmbedUrl(player.url, "dark")
            : player && setSpotifyEmbedUrl(player.url)}
            width="100%" 
            height={height}
            onLoad={() => setSlideIn(true)}
            >
          </iframe>
          {/* <iframe
            src={`https://open.spotify.com/embed/${currentPlayer.type}/${currentPlayer.id}?utm_source=generator&theme=0`}
            frameborder="0"
            width="100%"
            height="81"
            onLoad={() => setSlideIn(true)}
          ></iframe> */}
          <button onClick={handleOnCloseClick}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              vector-effect="non-scaling-stroke"
            >
              <path d="M0 0L20 20" stroke="white" vector-effect="non-scaling-stroke" />
              <path d="M0 20L20 0" stroke="white" vector-effect="non-scaling-stroke" />
            </svg>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default GlobalSpotifyPlayer
