import React from 'react'
import ArTile from './ar-tile'

function ArGrid({items}) {
  return (
    <>
      <ul className={`ar-grid`}>
          {console.log(items)}
          {items.map(item => <li key={item._key}><ArTile {...item}/></li>)}
      </ul>
    </>
  )
}

export default ArGrid